import { GoLocation} from "react-icons/go";
import { BsWhatsapp} from "react-icons/bs";
import { AiOutlineMail,AiOutlinePhone } from "react-icons/ai";
import { Link } from "react-router-dom";
export default function ContactInfo() {
    return (
        <div className=" revealRight ">
            <div className="">
                <div className="p-5 shadow-xl  border rounded-xl  ">

                    <h1 className="mb-4 text-4xl mt-5 text-center w-full font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-2xl">Contact Information</h1>

                    <div className="flex mx-auto px-16 items-center space-x-4">
                        <img className="w-20 h-20 rounded-full" src="https://media.istockphoto.com/id/1327592449/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=yqoos7g9jmufJhfkbQsk-mdhKEsih6Di4WZ66t_ib7I=" alt="" />
                        <div className="font-medium dark:text-white">
                            <div>Gerhard Lohmann</div>
                            <div className="text-sm text-adinda-2">Adinda Realty Corporation</div>
                        </div>

                    </div>
                    <div className="">
                        <h1 className=" font-semibold mt-5 mx-5"> Contact Number: </h1>
                        <p className="text-sm mt-5 mx-5  flex"><AiOutlinePhone className="h-5 w-5 mx-2 text-adinda-2"/>+63 999 884 1366</p>
                    </div>
                    <div className="">
                        <h1 className=" font-semibold mt-5 mx-5">Email Address:  </h1>
                        <p className="text-sm mt-5 mx-5 flex"><AiOutlineMail className="h-5 w-5 mx-2 text-adinda-2"/> gerhardpaul48@gmail.com</p>
                    </div>
                    <div className="">
                        <h1 className=" font-semibold mt-5 mx-5">Address:  </h1>
                        <Link to = "https://www.google.com/maps/place/Pulu+Amsic+Subdivision,+Angeles,+Pampanga/@15.1576932,120.5720653,16z/data=!4m6!3m5!1s0x3396f289c9493c7f:0xe39ddfcd73fc9e7b!8m2!3d15.1564604!4d120.5728788!16s%2Fg%2F11b8tb65kp?entry=ttu" 
                        className="text-sm mt-5 mx-5 flex hover:underline hover:text-adinda-2 hover:font-bold"><GoLocation className="h-5 w-5 mx-2 text-adinda-2"/>APO STREET, PULU AMSIC SUBDIVISION AMSIC, ANGELES</Link>
                    </div>
                    <a type="button" href="https://wa.me/+639998841366" className="text-white bg-green-500 mx-auto hover:bg-green-600  font-medium rounded-xl mt-5 text-sm px-40 py-2.5 text-center mb-2 flex"><BsWhatsapp className="h-5 w-5 mx-2 text-white"/>Whatsapp</a>
                    <h1 className=" font-semibold mt-5  w-full text-center text-adinda-2">www.adindarealty.com  </h1>



                </div>
            </div>
        </div>
    )
}