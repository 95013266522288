
import image_main from "../../Assets/HibokHibok/1.jpg"
import image_1 from "../../Assets/HibokHibok/2.jpg"
import image_2 from "../../Assets/HibokHibok/3.jpg"
import image_3 from "../../Assets/HibokHibok/4.jpg"
import image_4 from "../../Assets/HibokHibok/5.jpg"
import image_5 from "../../Assets/HibokHibok/6.jpg"
import image_6 from "../../Assets/HibokHibok/7.jpg"
import image_7 from "../../Assets/HibokHibok/8.jpg"
import image_8 from "../../Assets/HibokHibok/9.jpg"
import image_9 from "../../Assets/HibokHibok/10.jpg"
import image_10 from "../../Assets/HibokHibok/11.jpg"
import image_11 from "../../Assets/HibokHibok/12.jpg"
import image_12 from "../../Assets/HibokHibok/13.jpg"
import image_13 from "../../Assets/HibokHibok/14.jpg"
import image_14 from "../../Assets/HibokHibok/15.jpg"
import image_15 from "../../Assets/HibokHibok/16.jpg"
import image_16 from "../../Assets/HibokHibok/17.jpg"

import {
    HiOutlineHomeModern,
    HiArrowsPointingOut
} from "react-icons/hi2"
import {
    BiCar
} from "react-icons/bi"
import { LuSofa, LuBedDouble, LuBath } from "react-icons/lu"
import { RxRulerSquare, RxBorderSplit } from "react-icons/rx"

export const ModernHouse1 = {
    index: {
 
            house_name: "Modern House",
            house_description: "For sale 4 Bedroom with Swimming Pool, 5 Bathroom, 2 Carpark, 1 Maidsroom",
            address: "HIBOK-HIBOK STREET, BLOCK 20, LOT 6, PULU AMSIC SUBDIVISION AMSIC, ANGELES",
            price: "20,900,000",
            bed: 4,
            size: "240 sqm.",
            land: "252 sqm"
        
    },
    gallery:{
            image_main: image_main,
            image_1: image_1,
            image_2: image_2,
            image_3: image_3,
            image_4: image_4,
            image_5: image_5

    },
    details: [
            {icon: HiOutlineHomeModern, title : "Subdivision name", name: "Pulu Amsic Subdivision"},
            {icon: HiArrowsPointingOut, title : "Block and Lot/Unit Number", name: "Block 20, Lot 6"},
            {icon: BiCar, title : "Car Spaces", name: "2"},
            {icon: LuSofa, title : "Fully furnished", name: "No"},
            {icon: LuBedDouble, title : "Bedrooms", name: "4"},
            {icon: LuBath, title : "Bathrooms", name: "5"},
            {icon: RxRulerSquare, title : "Floor area (sqm.)", name: "240"},
            {icon: RxBorderSplit, title : "Land Size (sqm.)", name: "252"},
    ],
    collection: [
        {src : image_main},
        {src : image_1},
        {src : image_2},
        {src : image_3},
        {src : image_4},
        {src : image_5},
        {src : image_6},
        {src : image_7},
        {src : image_8},
        {src : image_9},
        {src : image_10},
        {src : image_11},
        {src : image_12},
        {src : image_13},
        {src : image_14},
        {src : image_15},
        {src : image_16},
    ]
      
}