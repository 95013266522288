
import image_main from "../../Assets/RGHouse/drone_2.jpg"
import image_1 from "../../Assets/RGHouse/1.jpg";
import image_2 from "../../Assets/RGHouse/2.jpg"
import image_3 from "../../Assets/RGHouse/3.jpg"
import image_4 from "../../Assets/RGHouse/4.jpg"
import image_5 from "../../Assets/RGHouse/5.jpg"
import image_6 from "../../Assets/RGHouse/6.jpg"
import image_7 from "../../Assets/RGHouse/7.jpg"
import image_8 from "../../Assets/RGHouse/8.jpg"
import image_9 from "../../Assets/RGHouse/9.jpg"
import image_10 from "../../Assets/RGHouse/10.jpg"
import image_11 from "../../Assets/RGHouse/11.jpg"
import image_12 from "../../Assets/RGHouse/12.jpg"
import image_13 from "../../Assets/RGHouse/13.jpg"
import image_14 from "../../Assets/RGHouse/14.jpg"
import image_15 from "../../Assets/RGHouse/15.jpg"
import image_16 from "../../Assets/RGHouse/16.jpg"
import image_17 from "../../Assets/RGHouse/17.jpg"
import image_18 from "../../Assets/RGHouse/18.jpg"
import image_19 from "../../Assets/RGHouse/19.jpg"
import image_20 from "../../Assets/RGHouse/20.jpg"
import image_21 from "../../Assets/RGHouse/21.jpg"
import image_22 from "../../Assets/RGHouse/22.jpg"
import image_23 from "../../Assets/RGHouse/23.jpg"
import image_24 from "../../Assets/RGHouse/24.jpg"
import image_25 from "../../Assets/RGHouse/25.jpg"
import image_26 from "../../Assets/RGHouse/26.jpg"
import image_27 from "../../Assets/RGHouse/27.jpg"
import image_28 from "../../Assets/RGHouse/28.jpg"
import image_29 from "../../Assets/RGHouse/29.jpg"
import image_30 from "../../Assets/RGHouse/30.jpg"
import image_31 from "../../Assets/RGHouse/31.jpg"
import image_32 from "../../Assets/RGHouse/32.jpg"
import image_33 from "../../Assets/RGHouse/33.jpg"
import image_34 from "../../Assets/RGHouse/34.jpg"
import image_35 from "../../Assets/RGHouse/35.jpg"
import image_36 from "../../Assets/RGHouse/36.jpg"
import image_37 from "../../Assets/RGHouse/37.jpg"
import image_38 from "../../Assets/RGHouse/38.jpg"
import image_39 from "../../Assets/RGHouse/39.jpg"
import image_40 from "../../Assets/RGHouse/40.jpg"
import image_41 from "../../Assets/RGHouse/41.jpg"
import image_42 from "../../Assets/RGHouse/42.jpg"
import image_43 from "../../Assets/RGHouse/43.jpg"
import image_44 from "../../Assets/RGHouse/44.jpg"
import image_45 from "../../Assets/RGHouse/45.jpg"
import image_46 from "../../Assets/RGHouse/46.jpg"
import image_47 from "../../Assets/RGHouse/47.jpg"
import image_48 from "../../Assets/RGHouse/48.jpg"
import image_49 from "../../Assets/RGHouse/49.jpg"
import image_50 from "../../Assets/RGHouse/50.jpg"
import image_51 from "../../Assets/RGHouse/51.jpg"
import image_52 from "../../Assets/RGHouse/52.jpg"
import image_53 from "../../Assets/RGHouse/53.jpg"
import image_54 from "../../Assets/RGHouse/54.jpg"
import image_55 from "../../Assets/RGHouse/55.jpg"
import image_56 from "../../Assets/RGHouse/56.jpg"
import image_57 from "../../Assets/RGHouse/57.jpg"

import { 
    HiOutlineHomeModern,
    HiArrowsPointingOut,
    HiOutlineWrenchScrewdriver
} from "react-icons/hi2";
import { 
    BiCar
} from "react-icons/bi";
import { LuSofa,LuBedDouble,LuBath, LuDoorOpen } from "react-icons/lu";
import { RxRulerSquare, RxBorderSplit } from "react-icons/rx";

export const VillaHouse = {
    index: {
 
            house_name: "Villa House",
            house_description: "Villa House 11 bedroom in Pulu Amsic Subdivision for sale",
            address: "APO STREET, PULU AMSIC SUBDIVISION AMSIC, ANGELES",
            price: "250,000,000",
            bed: 11,
            size: "2200 sqm.",
            land: "1850 sqm"
        
    },
    gallery:{
            image_main: image_main,
            image_1: image_18,
            image_2: image_12,
            image_3: image_5,
            image_4: image_34,
            image_5: image_25

    },
    details: [
            {icon: HiOutlineHomeModern, title : "Subdivision name", name: "Pulu Amsic Subdivision"},
            {icon: HiArrowsPointingOut, title : "Block and Lot/Unit Number", name: "Block 19, Lot 29,30,32,34,38"},
            {icon: HiOutlineWrenchScrewdriver, title : "Build (Year)", name: "2016"},
            {icon: BiCar, title : "Car Spaces", name: "6"},
            {icon: LuSofa, title : "Fully furnished", name: "Yes"},
            {icon: LuBedDouble, title : "Bedrooms", name: "11"},
            {icon: LuBath, title : "Bathrooms", name: "12"},
            {icon: LuDoorOpen, title : "Rooms(total)", name: "10"},
            {icon: RxRulerSquare, title : "Floor area (sqm.)", name: "2200"},
            {icon: RxBorderSplit, title : "Land Size (sqm.)", name: "1399"},
    ],
    collection: [
        {src : image_1},
        {src : image_2},
        {src : image_3},
        {src : image_4},
        {src : image_5},
        {src : image_6},
        {src : image_7},
        {src : image_8},
        {src : image_9},
        {src : image_10},
        {src : image_11},
        {src : image_12},
        {src : image_13},
        {src : image_14},
        {src : image_15},
        {src : image_16},
        {src : image_17},
        {src : image_18},
        {src : image_19},
        {src : image_20},
        {src : image_21},
        {src : image_22},
        {src : image_23},
        {src : image_24},
        {src : image_25},
        {src : image_26},
        {src : image_27},
        {src : image_28},
        {src : image_29},
        {src : image_30},
        {src : image_31},
        {src : image_32},
        {src : image_33},
        {src : image_34},
        {src : image_35},
        {src : image_36},
        {src : image_37},
        {src : image_38},
        {src : image_39},
        {src : image_40},
        {src : image_41},
        {src : image_42},
        {src : image_43},
        {src : image_44},
        {src : image_45},
        {src : image_46},
        {src : image_47},
        {src : image_48},
        {src : image_49},
        {src : image_50},
        {src : image_51},
        {src : image_52},
        {src : image_53},
        {src : image_54},
        {src : image_55},
        {src : image_56},
        {src : image_57},
        
    ]
      
}