import { useDispatch } from 'react-redux';
import HouseDetails from '../../Components/Houses/house';
import { useEffect } from 'react';
import { HouseData } from '../Actions/HouseAction';
import { ModernHouse3 } from '../../Configs/Houses/ModernHouse3';
export default function DispatchModernHouse3(){
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(HouseData(ModernHouse3))
    })
    return <HouseDetails/>
}