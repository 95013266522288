export default function HomePage() {
    return (
        <section className="revealTop text-adinda-bg">
            <div className="landing-section coverflow-hidden bg-gray-500 bg-blend-multiply">
                <div className="flex flex-col px-4 h-full mx-auto text-center justify-center items-center">
                    <div className="flex flex-col px-4 mx-auto text-center max-w-screen-2xl h-3/4 p-5 w-full justify-center items-center">
                        <div className="flex flex-col px-4 mx-auto text-center max-w-screen-2xl h-full w-full justify-center items-center">
                            <div className="flex text-center justify-center  items-center">
                                <div className="line mx-2">
                                    <h1 className="revealRight mt-2 overflow-hidden lg:text-4xl text-2xl text-transparent bg-clip-text bg-gradient-to-r from-adinda-1 to-adinda-2">Adinda</h1>
                                </div>
                                <h1 className="text-adinda-2 lg:text-5xl">|</h1>
                                <div className="line mx-2">
                                    <h1 className="revealLeft mt-2 overflow-hidden lg:text-4xl text-2xl text-transparent bg-clip-text bg-gradient-to-l from-adinda-1 to-adinda-2">Realty</h1>
                                </div>
                            </div>
                            <div className="overflow-hidden">
                                <hr className="fade h-px max-w-2xl lg:w-screen w-72 my-8 border-1" />
                            </div>
                            <div className="overflow-hidden">
                                <p className="revealTop overflow-hidden mt-2 text-4xl lg:text-6xl">A Comfortable home Your new option</p>
                            </div>
                            <div className="overflow-hidden">
                                <p className="revealBottom overflow-hidden mt-5 max-w-3xl text-xl">Home is where love resides, memories are created, friends always belong, and laughter never ends. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}