
import { Link } from 'react-router-dom';
export default function Maintenance() {
    return (
      <>
        <main className="grid min-h-full place-items-center bg-adinda-4 h-screen px-6 py-24 sm:py-40 lg:px-8">
          <div className="text-center">
            <p className="text-base font-semibold text-adinda-2">200</p>
            <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Comming Soon</h1>
            <p className="mt-6 text-base leading-7 text-gray-600">Sorry, this page is under development.</p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              
              <Link
                to="/"
                className="rounded-md bg-adinda-2 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-adinda-3 "
              >
                Go back home
              </Link>
              <Link to = "contact" className="text-sm font-semibold text-gray-900">
                Contact Us <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
        </main>
      </>
    )
  }
  