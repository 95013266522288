import { GoLocation } from "react-icons/go";
import Gallery from "./gallery";
import ContactInfo from "./contactinfo";
import Description from "./description";
import Footer from "../Header & Footer/Footer";
import Details from "./details";
import Adinda from "../Shared Components/Adinda";
import Collection from "./GalleryCollection";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

export default function HouseDetails() {
    const house = useSelector((state: RootState) => state.house);
    const { index, gallery, details, collection } = house
    const { house_name, house_description, address } = index
    console.log(house)
    return (
        <section className="bg-adinda-bg text-adinda-text">
            <section className="lg:ml-60 pt-20 px-5 ">
                <div className="revealLeft">
                    <div className="flex " >
                        <hr className="lg:w-20 w-5 border  border-adinda-1 mt-2 lg:mt-5 mx-2"></hr>
                        <h2 className="mb-4 lg:text-3xl font-extrabold leading-none tracking-tight text-adinda-2 ">For sale</h2>
                    </div>
                    <h2 className="mb-4 mt-5 text-3xl font-extrabold leading-none tracking-tight text-adinda-2  lg:text-5xl">{house_name}</h2>
                    <p className="text-sm text-gray-500">{house_description}</p>
                    <div className="flex">
                        <GoLocation className=" text-adinda-3 mt-1 w-5 h-5" />
                        <p className="mx-2 font-semibold mt-1">{address}</p>
                    </div>
                </div>
            </section>
            <div className=" grid lg:grid-cols-5 lg:ms-20  w-full">

                <Gallery gallery={gallery} />
                <Collection collection={collection} />
                <div className="hidden lg:flex mt-24 sticky ">
                    <ContactInfo />
                </div>
                <section className=" col-span-3  lg:px-20 px-10 ">
                    <Description index={index} />
                    <Details details={details} />
                    <Adinda />
                </section>


            </div>

            <Footer />
        </section>
    )
}