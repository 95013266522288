export default function Details(_props:any){
    const {details} = _props
    return(
        <section className="revealBottom mb-20 lg:ml-11 ">
            <h1 className="mt-10 text-xl lg:mx-2 font-semibold">Details</h1>
            <div className="grid lg:grid-cols-2 lg:ml-10 lg:px-5">
            {details.map((item: any)=> (
                <div className="grid grid-cols-2 mt-10 col-span-1">
                    <div className="col-span-1 flex"><item.icon className="w-5 h-5 mx-2 text-adinda-2"/><p className="text-sm font-semibold ">{item.title}</p></div>
                    <div className="col-span-1"><p className="text-sm ">{item.name}</p></div>
                </div>
                ))}
            </div>
        </section>

    )
}