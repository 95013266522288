import { Link } from "react-router-dom"
import image from "../../Assets/RGHouse/drone.jpg"
export default function Adinda() {
    return (
        <section className="fade text-adinda-text lg:mx-8 pb-10 rounded-xl">
            <div className="border-t grid lg:grid-cols-3">
                <div className="lg:col-span-2">
                    <h1 className="lg:mx-2 text-xl font-semibold mt-10">About Adinda</h1>

                    <p className="lg:mx-10 mx-2 text-sm lg:text-normal mt-5 text-adinda-text">Experience an exceptional residential Subdivision lifestyle at the heart of Angeles
                        Pulu Amsic Subdivision by Â is a residential Subdivision in Angeles, one of the top cities in Pampanga. Pulu Amsic Subdivision provides its residents with a lifestyle of privilege that many people can only dream of. Â is yet again breaking the boundarie...

                        <Link className="hover:underline text-adinda-2 mx-2 font-semibold hover:text-adinda-3" to="/about">view more</Link></p>

                </div>
                <div className="mt-5">
                    <img src={image} className="rounded-lg shadow-xl" alt=""></img>
                </div>
            </div>
        </section>
    )
}