import './App.css';
import { BrowserRouter,Routes,Route } from 'react-router-dom';
import Home from './Components/Home/index';
import Header from './Components/Header & Footer/Header';
import NotFound from './Components/404';
import HouseList from './Components/Houses/houseList';
import DispatchVilla from './Redux/Dispatch/DispatchVillaHouse';
import DispatchModernHouse1 from './Redux/Dispatch/DispatchModenHouse1';
import Maintenance from './Components/Maintenance';
import DispatchModernHouse2 from './Redux/Dispatch/DispatchModernHouse2';
import DispatchModernHouse3 from './Redux/Dispatch/DispatchModernHouse3';
import AboutUs from './Components/About Us';
function App() {
  return (
  <BrowserRouter>
  <Routes>
  <Route index element={<Home/>}/>
    <Route path='/' element={<Header/>}>
    <Route path='*' element={<NotFound/>}/>
    <Route path='properties' element={<HouseList/>}/>
    <Route path='villa-house' element={<DispatchVilla/>}/>
    <Route path='modern-house-1' element={<DispatchModernHouse1/>}/>
    <Route path='modern-house-2' element={<DispatchModernHouse2/>}/>
    <Route path='house-and-lot' element={<DispatchModernHouse3/>}/>
    <Route path='Contact' element={<Maintenance/>}/>
    <Route path='About' element={<AboutUs/>}/>
      </Route>
      
  </Routes>
  
  </BrowserRouter>
  );
}

export default App;
