const initialState = false;

const counterReducer = (state = initialState, action: { type: any; }) => {
  switch (action.type) {
    case 'START':
      return true;
    default:
      return state;
  }
};

export default counterReducer;