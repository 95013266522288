
import image_1 from "../../Assets/Lot12/1.png";
import image_2 from "../../Assets/Lot12/2.png"
import image_3 from "../../Assets/Lot12/3.png"
import image_4 from "../../Assets/Lot12/4.png"
import image_5 from "../../Assets/Lot12/5.png"
import image_6 from "../../Assets/Lot12/6.png"
import image_7 from "../../Assets/Lot12/7.png"
import image_8 from "../../Assets/Lot12/8.png"
import image_9 from "../../Assets/Lot12/9.png"
import image_10 from "../../Assets/Lot12/10.png"
import image_11 from "../../Assets/Lot12/11.png"
import image_12 from "../../Assets/Lot12/12.png"
import image_13 from "../../Assets/Lot12/13.png"
import image_14 from "../../Assets/Lot12/14.png"
import image_15 from "../../Assets/Lot12/15.png"
import image_16 from "../../Assets/Lot12/16.png"

import { 
    HiOutlineHomeModern,
    HiArrowsPointingOut,
} from "react-icons/hi2";
import { 
    BiCar
} from "react-icons/bi";
import { LuSofa,LuBedDouble,LuBath } from "react-icons/lu";
import { RxRulerSquare, RxBorderSplit } from "react-icons/rx";

export const ModernHouse2 = {
    index: {
 
            house_name: "Modern house",
            house_description: "3 Bedroom House with Swimming Pool, 4 Bathroom, Car Park, 1 Maidroom",
            address: "HIBOK-HIBOK, BLOCK 20, LOT 12, PULU AMSIC SUBDIVISION AMSIC, ANGELES",
            price: "19,000,000",
            bed: "3",
            size: "240 sqm.",
            land: "246 sqm."
        
    },
    gallery:{
            image_main: image_1,
            image_1: image_2,
            image_2: image_3,
            image_3: image_4,
            image_4: image_9,
            image_5: image_11

    },
    details: [  
            {icon: HiOutlineHomeModern, title : "Subdivision name", name: "Pulu Amsic Subdivision"},
            {icon: HiArrowsPointingOut, title : "Block and Lot/Unit Number", name: "Block 20, Lot 12"},
            {icon: BiCar, title : "Car Spaces", name: "4"},
           {icon: LuSofa, title : "Fully furnished", name: "No"},
            {icon: LuBedDouble, title : "Bedrooms", name: "3"},
            {icon: LuBath, title : "Bathrooms", name: "4"},
            {icon: RxRulerSquare, title : "Floor area (sqm.)", name: "240"},
            {icon: RxBorderSplit, title : "Land Size (sqm.)", name: "246"},
    ],
    collection: [
        {src : image_1},
        {src : image_2},
        {src : image_3},
        {src : image_4},
        {src : image_5},
        {src : image_6},
        {src : image_7},
        {src : image_8},
        {src : image_9},
        {src : image_10},
        {src : image_11},
        {src : image_12},
        {src : image_13},
        {src : image_14},
        {src : image_15},
        {src : image_16},
    ]
      
}