import { Link } from "react-router-dom";
import image from "../../Assets/RGHouse/drone.jpg"
import { HiLocationMarker } from "react-icons/hi";
import Adinda from "../Shared Components/Adinda";
import FooterWithSocialMediaIcons from "../Header & Footer/Footer";
export default function AboutUs(){


    return(
    <section className="bg-adinda-bg h-screen text-adinda-text">
    <div className=" lg:mx-20 mx-5 py-24 sm:py-32">

          <div className="revealTop mx-auto text-center lg:mx-0">
            <h2 className="text-4xl font-semibold tracking-tight mx-2">ABOUT US</h2>
            <p className="mt-2 text-lg leading-8 text-adinda-2 font-serif">
            Pulu Amsic Subdivision
            </p>
          </div>

          <div className=" lg:grid mt-5 lg:mt-20 lg:grid-cols-2">
            <div className="mt-10">
                <h1 className="text-2xl font-bold ">Adinda Realty</h1>
                <p className="text-gray-600 mt-5 text-justify ">Experience an exceptional residential Subdivision lifestyle at the heart of Angeles
Pulu Amsic Subdivision by Â is a residential Subdivision in Angeles, one of the top cities in Pampanga. Pulu Amsic Subdivision provides its residents with a lifestyle of privilege that many people can only dream of. Â is yet again breaking the boundaries of residential living by bringing comfort and affordability, ensuring residents live the life they deserve as they create more amazing memories with their families.
</p>
<p className="text-gray-600 mt-10 text-justify ">These makes getting a unit at Pulu Amsic Subdivision worth every cent. Properties within Amsic area are quite strategically-located that's why owning a Subdivision is a worthwhile investment one can make.</p>
            <p className="text-gray-600 mt-10 text-justify">
            Choosing the right location is one of the first and main factors in deciding when buying or renting a property. Pulu Amsic Subdivision is in a prime location in Amsic, Angeles, within a convenient distance from establishments like schools, churches, malls, offices and restaurants, making a Pulu Amsic Subdivision purchase perfect for those starting a family, a student or a professional looking for a place near their work and school.

            </p>
            <Link to = "https://www.google.com/maps/place/Pulu+Amsic+Subdivision,+Angeles,+Pampanga/@15.1576932,120.5720653,16.55z/data=!4m6!3m5!1s0x3396f289c9493c7f:0xe39ddfcd73fc9e7b!8m2!3d15.1564604!4d120.5728788!16s%2Fg%2F11b8tb65kp?entry=ttu" className="mt-10 flex hover:underline hover:text-adinda-2">
                <HiLocationMarker className="h-5 w-5 lg:mx-2   "/>
                <p className="" > Pulu Amsic, Angeles City Pampanga </p>
            </Link>
            </div>
            <div className="text-center">
            <img src={image} className=" lg:m-10 mt-4 lg:mt-0 rounded-lg shadow-xl" alt=""></img>
            </div>

          </div>
        </div>
      <FooterWithSocialMediaIcons></FooterWithSocialMediaIcons>
        </section>
        
                        
                        
                        )
}