import { featured } from "../../Configs/HomeLink"
import { MapPinIcon, HomeModernIcon } from '@heroicons/react/24/outline'
import { LuBedDouble } from "react-icons/lu";
import { RxRulerSquare, RxBorderSplit} from "react-icons/rx";
import { Link } from "react-router-dom";

  export default function Featured() {
    return (
      <div className="revealLeft text-adinda-text py-24 sm:py-32">

          <div className="mx-auto text-center lg:mx-0">
            <h2 className="text-4xl font-semibold tracking-tight mx-2">FEATURED PROPERTIES</h2>
            <p className="mt-2 text-lg leading-8 text-adinda-2 font-serif">
              Check our Featured Properties
            </p>
          </div>
          <div className="mx-auto mt-10  lg:mx-0">
        <div className="mx-auto max-w-7xl">
            <dl className=" grid gap-8 mx-10 lg:mx-5 grid-cols-1 lg:grid-cols-3">
              {featured.map((stat) => (
                <Link to={stat.href}>
                <div className="hover:shadow-xl shadow-lg transition ease-in-out  hover:-translate-y-1 hover:scale-110  duration-300">
                <div className="parent">
                        <img src={stat.img} alt="" className="child"></img>
                        <h1 className="child bg-adinda-text m-3 w-24 h-9 p-2 text-sm text-white hover:bg-black">FOR SALE</h1>
                        
                        </div>
                    
                    <div key={stat.name} className={stat.class}>
                    <div className="flex mx-5 mt-6  mb-2">
                    <HomeModernIcon className="h-6 w-6   text-adinda-2" aria-hidden="true" />
                    <dd className=" text-xl  mx-2 font-semibold  ">{stat.value}</dd>
                    </div>
                    <div className="flex mx-5 mb-2">
                    <MapPinIcon className="h-6 w-6 p-1 border rounded-md border-adinda-2 text-adinda-2" aria-hidden="true" />
                    <dt className="mx-1 leading-6 text-sm text-adinda-3">{stat.name}</dt>
                    </div>
                    <p className="text-gray-500 text-sm mx-5 font-semibold">{stat.description}</p>
                    <div className="flex mx-5 mb-2 mt-5 justify-between">
                      
                      <LuBedDouble className="text-adinda-2 h-6 w-6"/>
                      <h1 className="text-adinda-2 text-sm font-bold">{stat.bed}</h1>
                      
                      <RxRulerSquare className="text-adinda-2 h-5 w-5"/>
                      <h1 className="text-adinda-2 text-sm font-bold">{stat.sqm}</h1>
                      <RxBorderSplit className="text-adinda-2 h-5 w-5"/>
                      <h1 className="text-adinda-2 text-sm font-bold">{stat.land}</h1>
                      
                      </div>
                      <div className="flex mx-5 mb-2  justify-between">
                      <h1 className="text-adinda-text text-sm font-bold">Bedroom</h1>
                      
                      <h1 className="text-adinda-text text-sm font-bold">Floor Area</h1>
                      <h1 className="text-adinda-text text-sm font-bold">Land Area</h1>
                      
                      </div>
                </div>
                </div>
                </Link>
                
              ))}
            </dl>
      
            
            </div>
          </div>
     </div>
    )
  }
  