const initialState = false;

const collectionReducer = (state = initialState, action: { type: any; }) => {
  switch (action.type) {
    case 'OPEN_COLLECTION':
      return true;
      case "CLOSE_COLLECTION":
        return false;
    default:
      return state;
  }
};

export default collectionReducer;