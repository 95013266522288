import rg_house from "../Assets/RGHouse/drone.jpg"
import hibok from "../Assets/HibokHibok/1.jpg"
import lot12 from "../Assets/Lot12/1.png"
import lot24 from "../Assets/Lot24/4.jpg"
export const products = [
    {
      name: 'Pulu Amsic Subdivision, Angeles',
      value: "Villa House",
      img: rg_house, description: "Villa House 11 bedroom in Pulu Amsic Subdivision",
      href: "/villa-House",
      class: "pb-16 pt-10 ",
      bed: 11,
      land : "1399 sqm.",
      sqm : "2200 sqm."
    },
    {
      name: 'Pulu Amsic Subdivision, Angeles',
      value: 'Modern House',
      img: hibok, description: "4 Bedroom with Swimming Pool, 5 Bathroom, 2 Carpark, 1 Maidsroom",
      href: "/modern-house-1",
      class: "py-5",
      bed: 4,
      land : "252 sqm.",
      sqm : "240 sqm."
    },
    {
      name: 'Pulu Amsic Subdivision, Angeles',
      value: 'Modern House',
      img: lot12, description: "3 Bedroom House with Swimming Pool, 4 Bathroom, Car Park, 1 Maidroom",
      href: "/modern-house-2",
      class: "py-5",
      bed: 3,
      land : "246 sqm.",
      sqm : "240 sqm."
    },
    {
        name: 'Pulu Amsic Subdivision, Angeles',
        value: 'Modern House',
        img: lot24, description: "House and Lot, 4 Bedroom, Swimming with Bar",
        href: "/house-and-lot",
        class: "py-5",
        bed: 4,
        land : "242 sqm.",
        sqm : "320 sqm."
      },
  ]