import image_1 from "../../Assets/Lot24/1.jpg";
import image_2 from "../../Assets/Lot24/2.jpg"
import image_3 from "../../Assets/Lot24/3.jpg"
import image_4 from "../../Assets/Lot24/4.jpg"
import image_5 from "../../Assets/Lot24/5.jpg"
import image_6 from "../../Assets/Lot24/6.jpg"
import image_7 from "../../Assets/Lot24/7.jpg"
import image_8 from "../../Assets/Lot24/8.jpg"
import image_9 from "../../Assets/Lot24/9.jpg"
import image_10 from "../../Assets/Lot24/10.jpg"
import image_11 from "../../Assets/Lot24/11.jpg"
import image_12 from "../../Assets/Lot24/12.jpg"
import image_13 from "../../Assets/Lot24/13.jpg"
import image_14 from "../../Assets/Lot24/14.jpg"
import image_15 from "../../Assets/Lot24/15.jpg"
import image_16 from "../../Assets/Lot24/16.jpg"
import image_17 from "../../Assets/Lot24/17.jpg"
import image_18 from "../../Assets/Lot24/18.jpg"
import image_19 from "../../Assets/Lot24/19.jpg"
import image_20 from "../../Assets/Lot24/20.jpg"
import image_21 from "../../Assets/Lot24/21.jpg"
import image_22 from "../../Assets/Lot24/22.jpg"
import image_23 from "../../Assets/Lot24/23.jpg"
import image_24 from "../../Assets/Lot24/24.jpg"
import image_25 from "../../Assets/Lot24/25.jpg"
import image_26 from "../../Assets/Lot24/26.jpg"
import image_27 from "../../Assets/Lot24/27.jpg"

import { 
    HiOutlineHomeModern,
    HiArrowsPointingOut,
    HiOutlineWrenchScrewdriver
} from "react-icons/hi2";
import { LuSofa,LuBedDouble,LuBath, LuDoorOpen } from "react-icons/lu";
import { RxRulerSquare, RxBorderSplit } from "react-icons/rx";

export const ModernHouse3 = {
    index: {
 
            house_name: "Modern House",
            house_description: "Fully furnished House and Lot in Pulu Amsic for Sale",
            address: "PULU AMSIC SUBDIVISION AMSIC, ANGELES",
            price: "25,900,000",
            bed: 4,
            size: "300 sqm.",
            land: "240 sqm"
        
    },
    gallery:{
            image_main: image_4,
            image_1: image_15,
            image_2: image_7,
            image_3: image_10,
            image_4: image_13,
            image_5: image_14

    },
    details: [
            {icon: HiOutlineHomeModern, title : "Subdivision name", name: "Pulu Amsic Subdivision"},
            {icon: HiArrowsPointingOut, title : "Block and Lot/Unit Number", name: "Block 20, Lot 24"},
            {icon: LuSofa, title : "Fully furnished", name: "Yes"},
            {icon: LuBedDouble, title : "Bedrooms", name: "4"},
            {icon: LuBath, title : "Bathrooms", name: "5"},
            {icon: LuDoorOpen, title : "Rooms(total)", name: "10"},
            {icon: RxRulerSquare, title : "Floor area (sqm.)", name: "300"},
            {icon: RxBorderSplit, title : "Land Size (sqm.)", name: "240"},
    ],
    collection: [
        
        {src : image_1},
        {src : image_2},
        {src : image_3},
        {src : image_4},
        {src : image_5},
        {src : image_6},
        {src : image_7},
        {src : image_8},
        {src : image_9},
        {src : image_10},
        {src : image_11},
        {src : image_12},
        {src : image_13},
        {src : image_14},
        {src : image_15},
        {src : image_16},
        {src : image_17},
        {src : image_18},
        {src : image_19},
        {src : image_20},
        {src : image_21},
        {src : image_22},
        {src : image_23},
        {src : image_24},
        {src : image_25},
        {src : image_26},
        {src : image_27},

    ]
      
}