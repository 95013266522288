import { LuBedDouble } from "react-icons/lu";
import { RxRulerSquare, RxBorderSplit } from "react-icons/rx";
import { TbCurrencyPeso } from "react-icons/tb";
export default function Description(_props: any){
    const {index} = _props
    const {house_description,price,bed,size,land} = index
    return(
        <section className="revealLeft lg:ml-11 ">
            <h1 className="lg:text-4xl text-xl font-semibold ">{house_description}</h1>
            <h1 className="lg:text-4xl text-xl font-semibold flex  mt-5 text-adinda-3"><TbCurrencyPeso className="lg:w-10 lg:h-10 w-7 h-7"/>{price}</h1>
            <div className="flex mx-2 mb-2 mt-5 lg:mt-10 justify-between lg:max-w-sm lg:pr-24">
                      <LuBedDouble className="text-adinda-2 h-6 w-6"/>
                      <h1 className="text-adinda-2 text-sm font-bold">{bed}</h1>
                      
                      <RxRulerSquare className="text-adinda-2 h-5 w-5"/>
                      <h1 className="text-adinda-2 text-sm font-bold">{size}</h1>
                      <RxBorderSplit className="text-adinda-2 h-5 w-5"/>
                      <h1 className="text-adinda-2 text-sm font-bold">{land}</h1>
                      
                      </div>
                      <hr></hr>
                
        </section>
    )
}