const initialState = {index: {},
gallery:{},
details: [],
collection: []};

const houseReducer = (state = initialState, action: { type: any,data: any }) => {
  switch (action.type) {
    case 'HOUSE_DATA':
      return action.data;
    default:
      return state;
  }
};

export default houseReducer;