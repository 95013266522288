import { Fragment, } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { Link } from 'react-router-dom'
import { products, callsToAction } from '../../Configs/NavLinks'
export default function PopoverNav(){
    return (
        <Popover className="relative">
        <Popover.Button className="flex items-center gap-x-1 font-semibold leading-6 transition ease-in-out  hover:-translate-y-1 hover:scale-110  duration-300">
          Houses
          <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-adinda-bg shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4">
              {products.map((item) => (
                <div
                  key={item.name}
                  className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-adinda-4"
                >
                  <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-adinda-bg group-hover:bg-white">
                    <item.icon className="h-6 w-6 text-gray-600 group-hover:text-adinda-2" aria-hidden="true" />
                  </div>
                  <div className="flex-auto">
                    <Link to={item.href} className="block font-semibold text-gray-900">
                      {item.name}
                      <span className="absolute inset-0" />
                    </Link>
                    <p className="mt-1 text-gray-600">{item.description}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="grid grid-cols-1 divide-x divide-gray-900/5 bg-adinda-bg border-t">
              {callsToAction.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-300"
                >
                  <item.icon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  {item.name}
                </Link>
              ))}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>

    )
}