import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { OpenCollection } from "../../Redux/Actions/GallerCollectionAction";
import { url } from "inspector";
import { MdAddAPhoto } from "react-icons/md";
export default function Gallery(_props: any) {
    const dispatch = useDispatch();
    const { gallery } = _props
    const { image_main, image_1, image_2, image_3, image_4, image_5 } = gallery
    return (
        <>

            <div className=" fade container col-span-3 ">
                <div className="grid-cols-3 lg:pt-20 lg:px-24 p-5 m-5 lg:space-y-0 grid gap-2  lg:grid-rows-3">
                    <div className="w-full rounded">
                        <img src={image_1}
                            alt="" />
                    </div>
                    <div className="w-full col-span-2 row-span-2 rounded">
                        <img src={image_main}
                            alt="" />
                    </div>
                    <div className="w-full rounded">
                        <img src={image_2}
                            alt="" />
                    </div>
                    <div className="w-full rounded">
                        <img src={image_3}
                            alt="" />
                    </div>
                    <div className="w-full rounded">
                        <img src={image_4}
                            alt="" />
                    </div>
                    <Link className="w-full bg-black container" onClick={() => dispatch(OpenCollection())} to={""}>
                    <img className=" opacity-30 hover:opacity-50" src={image_5}
                            alt="" />
                        <h1 className="centered lg:text-xl text-sm font-extrabold tracking-tight leading-none hover:text-white ">View Photo <MdAddAPhoto className="mx-auto lg:h-5 h-4 w-4 lg:w-5"/></h1>
                    

                       
                        

                    </Link>

                </div>
            </div>


        </>
    )
}