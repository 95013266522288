import { Popover } from '@headlessui/react'
import {
  Bars3Icon
} from '@heroicons/react/24/outline'
import { Link, Outlet } from 'react-router-dom'
import { logo ,navlinks } from '../../Configs/NavLinks'
import { OpenDialog } from '../../Redux/Actions/DialogActions'
import { useDispatch } from 'react-redux'
import DialogNav from './Dialog'
import PopoverNav from './Popover'
export default function Header() {
  const dispatch = useDispatch();
  return (
    <section className=''>
      <header className="bg-adinda-text text-white shadow-xl w-full">
        <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link to="/" className="-m-1.5 p-1.5 flex">
              <img className="h-10 w-auto" src={logo} alt="" />
              <h1 className='text-3xl text-transparent bg-clip-text bg-gradient-to-r from-adinda-1 to-adinda-2 hover:text-adinda-3'>Adinda</h1>
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
              onClick={() => dispatch(OpenDialog())}
            ><span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          </div>
          <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <Link to="/" className=" leading-6 transition ease-in-out  hover:-translate-y-1 hover:scale-110  duration-300 hover:underline">Home</Link>
            <PopoverNav />
            {navlinks.map((item) =>(
              <Link to={item.href} className="leading-6 transition ease-in-out  hover:-translate-y-1 hover:scale-110  duration-300 hover:underline">{item.name}</Link>
            ))}
          </Popover.Group>

          
        </nav>
        <DialogNav />

      </header>
      <Outlet />
    </section>
  )
}
