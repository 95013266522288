const initialState = false;

const dialogReducer = (state = initialState, action: { type: any; }) => {
  switch (action.type) {
    case 'OPEN_DIALOG':
      return true;
      case "CLOSE_DIALOG":
        return false;
    default:
      return state;
  }
};

export default dialogReducer;