'use client';

import FooterWithSocialMediaIcons from "../Header & Footer/Footer";
import Header from "../Header & Footer/Header";
import HomePage from "./home";
import Landing from "./landing";
import { useSelector } from 'react-redux';
import Featured from "./Featured";
import { RootState } from "../../Redux/store";
import Adinda from "../Shared Components/Adinda";
export default function Home() {
    const toggle = useSelector((state: RootState) => state.counter); 
    return (
        <section className="bg-adinda-bg">
            {!toggle ? <Landing></Landing> :
                <div className="w-screen">
                    <Header />
                    <HomePage/>
                    <Featured/>
                    <div className="lg:mx-20 mx-2"> <Adinda/></div>
                    
                    <FooterWithSocialMediaIcons />
                </div>
            }
        </section>
    )
}