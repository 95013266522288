import { HomeModernIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { LuBedDouble } from "react-icons/lu";
import { RxRulerSquare, RxBorderSplit } from "react-icons/rx";
import { Link } from "react-router-dom";
import { products } from "../../Configs/HouselistLinks";
import Footer from "../Header & Footer/Footer";
import Adinda from "../Shared Components/Adinda";

export default function HouseList() {
    return (<>
        <section className="lg:p-60 py-20 p-10 bg-adinda-bg  ">
            <div className="revealLeft">
            <div className="flex " >
            <hr className="lg:w-20 w-5 border  border-adinda-1 mt-2 lg:mt-5 mx-2"></hr>
            <h2 className="mb-4 lg:text-3xl font-extrabold leading-none tracking-tight text-adinda-2 ">House List</h2>
        </div>
        <h2 className="mb-4 mt-5 text-3xl font-extrabold leading-none tracking-tight text-adinda-2  lg:text-5xl">HOUSE LIST</h2>
            <p className="text-sm text-gray-500">Experience an exceptional residential Subdivision lifestyle at the heart of Angeles
                Pulu Amsic Subdivision</p>
                </div>
                <dl className="lg:mt-20 lg:mx-5 mb-20  lg:grid-cols-3 grid fade">
            {products.map((stat) => (
                <Link to={stat.href}>
                    <div className=" lg:mx-5 shadow-lg hover:shadow-xl mt-10 transition ease-in-out  hover:-translate-y-1 hover:scale-110  duration-300">
                        <div className="parent">
                        <img src={stat.img} alt="" className="child"></img>
                        <h1 className="child bg-adinda-text m-3 w-24 h-9 p-2 text-sm text-white hover:bg-black">FOR SALE</h1>
                        
                        </div>
                        <div key={stat.name} className={stat.class}>
                            <div className="flex mx-5 mt-6  mb-2">
                                <HomeModernIcon className="h-7 w-7   text-adinda-2" aria-hidden="true" />
                                <dd className=" text-2xl  mx-2 font-semibold  ">{stat.value}</dd>
                            </div>
                            <div className="flex mx-5 mb-2">
                                <MapPinIcon className="h-6 w-6 p-1 border rounded-md border-adinda-2 text-adinda-2" aria-hidden="true" />
                                <dt className="mx-1 leading-6 text-sm text-adinda-3">{stat.name}</dt>
                            </div>
                            <p className="text-gray-500 text-sm mx-5 font-semibold">{stat.description}</p>
                            <div className="flex mx-5 mb-2 mt-5 justify-between">
                                <LuBedDouble className="text-adinda-2 h-6 w-6" />
                                <h1 className="text-adinda-2 text-sm font-bold">{stat.bed}</h1>

                                <RxRulerSquare className="text-adinda-2 h-5 w-5" />
                                <h1 className="text-adinda-2 text-sm font-bold">{stat.sqm}</h1>
                                <RxBorderSplit className="text-adinda-2 h-5 w-5" />
                                <h1 className="text-adinda-2 text-sm font-bold">{stat.land}</h1>

                            </div>
                            <div className="flex mx-5 mb-2  justify-between">
                                <h1 className="text-adinda-1 text-sm font-bold">Bedroom</h1>

                                <h1 className="text-adinda-1 text-sm font-bold">Floor Area</h1>
                                <h1 className="text-adinda-1 text-sm font-bold">Land Area</h1>

                            </div>
                        </div>
                    </div>
                </Link>

            ))}
            
            </dl>
            <Adinda/>
        </section>
        <Footer/>
        </>
    )
}