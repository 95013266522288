import { combineReducers } from 'redux';
import counterReducer from './HomeToggleRegucer';
import dialogReducer from './DialogReducer';
import collectionReducer from './GalleryCollectionReducer';
import houseReducer from './HouseReducer';


const rootReducer = combineReducers({
  counter: counterReducer,
  dialog: dialogReducer,
  collection: collectionReducer,
  house: houseReducer
});

export default rootReducer;