'use client';
import { Button, Modal } from 'flowbite-react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseCollection } from '../../Redux/Actions/GallerCollectionAction';
import { RootState } from '../../Redux/store';
import { useState } from 'react';
import { useGalleryPage } from '../../Hooks/GalleryPageHooks';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
export default function Collection(_props: any) {
    const { collection } = _props
    const toggle = useSelector((state: RootState) => state.collection);
    const dispatch = useDispatch();
    return (
        <>
         <Lightbox
        open={toggle}
        close={() => dispatch(CloseCollection())}
        slides={
          collection
          
        }
        
      />
      
        </>
    )
}
