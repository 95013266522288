
import {
    HomeModernIcon
} from '@heroicons/react/24/outline'
import { PhoneIcon } from '@heroicons/react/20/solid'
export const logo = ""
export const products = [
    { name: 'Villa House', description: 'Villa House 11 bedroom in Pulu Amsic Subdivision', href: 'villa-house', icon: HomeModernIcon },
    { name: 'Modern House', description: '4 Bedroom with Swimming Pool, 5 Bathroom, 2 Carpark, 1 Maidsroom', href: 'modern-house-1', icon: HomeModernIcon },
    { name: 'Modern House', description: '3 Bedroom House with Swimming Pool, 4 Bathroom, Car Park, 1 Maidroom', href: 'modern-house-2', icon: HomeModernIcon },
    { name: 'Modern House', description: 'House and Lot, 4 Bedroom, Swimming with Bar', href: 'house-and-lot', icon: HomeModernIcon },
]
export const callsToAction = [
    { name: 'View All Houses', href: 'properties', icon: HomeModernIcon },
]
export const navlinks = [
    {name: 'About Us', href: '/about'},

    
    
]
   
