'use client';
import { Footer } from 'flowbite-react';
import { Link } from 'react-router-dom';
import { logo } from '../../Configs/NavLinks';
import { about, legal } from '../../Configs/FooterLinks';
export default function FooterWithSocialMediaIcons() {
  return (
    <Footer container className='border-t text-adinda-text bg-adinda-bg rounded-none '>
      <div className="w-full ">
        <div className="grid h-64 w-full lg:px-40 justify-between sm:flex sm:justify-between md:flex md:grid-cols-1">
          <div>
            <Footer.Brand
              alt=""
              href="/"
              name="Adinda Realty"
              src={logo}
            />
          </div>
          <div className="grid grid-cols-2 lg:first-letter:mx-20 gap-8 sm:mt-4 sm:grid-cols-3 sm:gap-6">
            <div>
              <Footer.Title title="About" />
              <Footer.LinkGroup col>
                {about.map((item) => (
                  <Link to={item.href} className='hover:underline hover:text-black'>
                    {item.name}
                  </Link>
                ))}
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Follow us" />
              <Footer.LinkGroup col>
                <div className='flex'>
                 
                </div>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title title="Legal" />
              <Footer.LinkGroup col>
                {legal.map((item) => (
                  <Link to={item.href}>
                    {item.name}
                  </Link>
                ))}
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider className='border-t-gray-300' />
        <div className="w-full lg:mx-10 sm:flex sm:items-center mt-2 sm:justify-between">
          <Footer.Copyright
            by=" Adinda Realty"
            year={2023}
          />


        </div>
      </div>
    </Footer>
  )
}


