
import { Dialog, Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import { callsToAction, logo, navlinks, products } from '../../Configs/NavLinks'
import { classNames } from '../../Services/Home'
import { useDispatch, useSelector } from 'react-redux'
import { CloseDialog } from '../../Redux/Actions/DialogActions'
import { RootState } from '../../Redux/store'
export default function DialogNav() {
    const toggle = useSelector((state: RootState) => state.dialog);
    const dispatch = useDispatch();
    return (
        <Dialog as="div" className="lg:hidden" open={toggle} onClose={() => dispatch(CloseDialog())}>
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-adinda-4 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                <div className="flex items-center justify-between">
                    <Link to="/" className="-m-1.5 p-1.5">
                        <span className="sr-only">Your Company</span>
                        <img className="h-8 w-auto" src={logo} alt="" />
                        <h1 className='text-3xl text-transparent bg-clip-text bg-gradient-to-r from-adinda-1 to-adinda-2 hover:text-adinda-3'>Adinda</h1>
                    </Link>
                    <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => dispatch(CloseDialog())}>
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-6 flow-root">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-2 py-6">
                            <Disclosure as="div" className="-mx-3">
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button className="flex text- w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                                            Houses
                                            <ChevronDownIcon
                                                className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-2 space-y-2">
                                            {[...products, ...callsToAction].map((item) => (
                                                <Disclosure.Button
                                                    key={item.name}
                                                    as="a"
                                                    href={item.href}
                                                    className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                                >
                                                    {item.name}
                                                </Disclosure.Button>
                                            ))}
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            {navlinks.map((item) => (
                                <Link to={item.href}
                                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                                >
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                        <div className="py-6">
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}