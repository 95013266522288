import rg_house from "../Assets/RGHouse/drone.jpg"
import hibok from "../Assets/HibokHibok/1.jpg"
import lot12 from "../Assets/Lot12/1.png"
import lot24 from "../Assets/Lot24/20180920_100213.jpg"
export const featured = [
  {
    name: 'Pulu Amsic Subdivision, Angeles',
    value: "Villa House",
    img: rg_house, description: "Villa House 11 bedroom in Pulu Amsic Subdivision",
    href: "/villa-House",
    class: "py-9",
    bed: 11,
    land : "1399 sqm.",
    sqm : "2200 sqm."
  },
  {
    name: 'Pulu Amsic Subdivision, Angeles',
    value: 'Modern House',
    img: hibok, description: "4 Bedroom with Swimming Pool, 5 Bathroom, 2 Carpark, 1 Maidsroom",
    href: "/modern-house-1",
    class: "pb-4",
    bed: 4,
    land : "252 sqm.",
    sqm : "240 sqm."
  },
  {
    name: 'Pulu Amsic Subdivision, Angeles',
    value: 'Modern House',
    img: lot12, description: "3 Bedroom House with Swimming Pool, 4 Bathroom, Car Park, 1 Maidroom",
    href: "/modern-house-2",
    class: " rounded-lg pb-4",
    bed: 3,
    land : "246 sqm.",
    sqm : "240 sqm."
  },
]