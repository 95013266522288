'use client';
import { Button } from 'flowbite-react';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { useDispatch } from 'react-redux';
import { toggle } from '../../Redux/Actions/action';
export default function Landing() {
    const dispatch = useDispatch();
    return (
        <section className="h-screen bg-center text-white bg-cover landing bg-gray-500 bg-blend-multiply">
            <div className="flex flex-col px-4 h-full mx-auto text-center justify-center items-center">
                <div className="flex flex-col px-4 mx-auto text-center max-w-screen-2xl h-3/4 p-5 w-full  justify-center items-center">
                    <div className="flex flex-col px-4 mx-auto text-center max-w-screen-2xl h-full w-full  justify-center items-center">
                        <div className="flex text-center justify-center  items-center">
                            <div className="line mx-2">
                                <h1 className="revealRight mt-2 overflow-hidden text-xl lg:text-4xl text-transparent bg-clip-text bg-gradient-to-r from-adinda-1 to-adinda-2">Adinda</h1>
                            </div>
                            <h1 className="text-adinda-2 text-xl lg:text-5xl">|</h1>
                            <div className="line mx-2">
                                <h1 className="revealLeft mt-2 overflow-hidden text-xl lg:text-4xl text-transparent bg-clip-text bg-gradient-to-l from-adinda-1 to-adinda-2">Realty</h1>
                            </div>
                        </div>
                        <div className="overflow-hidden">
                            <hr className="fade max-w-2xl lg:w-96 w-52 lg:my-8 border-1" />
                        </div>
                        <div className="overflow-hidden">
                            <p className="revealTop overflow-hidden mt-2 text-4xl lg:text-6xl">A Comfortable home Your new option</p>
                        </div>
                        <div className="overflow-hidden">
                            <p className="revealBottom overflow-hidden mt-5 max-w-3xl lg:text-xl">Home is where love resides, memories are created, friends always belong, and laughter never ends. </p>
                        </div>
                        <Button className='fade mt-5 hover:border-transparent mb-2 shadow-lg  hover:bg-adinda-text'
                            onClick={() => dispatch(toggle())}>
                            <p className=' py-1 hover:underline'>
                                Get Started
                            </p>
                            <HiOutlineArrowRight className="ml-2 h-5 w-5 hover:animate-ping" />
                        </Button>
                    </div>
                </div>
            </div>

        </section>

    )
}